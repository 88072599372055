import { useQueryClient } from '@tanstack/vue-query'
import { NavigationGuard, RouteLocationNormalized } from 'vue-router'
import { Company, companySchema } from '@/api/useCompany.types'
import { universalTypedFetch } from '@/api/utils/factory'
import { Endpoint, useUrl } from '@/api/utils/url'
import { getStaticUserData } from '@/api/utils/user'

export const patchMissingCompanyId: NavigationGuard = (to, from) => {
  const { companyId } = getStaticUserData()

  if (companyId && !to.params.companyId) {
    const expectedCompanyId = from.name
      ? Number(from.params.companyId)
      : companyId

    return replaceCompanyId(to, expectedCompanyId)
  }
}

export const companySwitchRedirect: NavigationGuard = (to, from) => {
  if (
    from.name &&
    to.meta.companySwitchRedirect &&
    to.params.companyId !== from.params.companyId
  ) {
    return {
      ...to.meta.companySwitchRedirect,
      params: {
        ...to.meta.companySwitchRedirect.params,
        companyId: to.params.companyId,
      },
    }
  }
}

export const companyAccessGuard: NavigationGuard = async (to) => {
  const { companyId } = getStaticUserData()

  if (companyId && !(await hasAccessToCompany(to))) {
    return replaceCompanyId(to, companyId)
  }
}

const hasAccessToCompany = async (
  to: RouteLocationNormalized
): Promise<boolean> => {
  const { getCompaniesCacheFirst } = useFetchCompanyWithDescendants()
  const companies = await getCompaniesCacheFirst()
  const companyId = Number(to.params.companyId)

  return companies?.some((company) => company.id === companyId) ?? false
}

const replaceCompanyId = (
  to: RouteLocationNormalized,
  companyId: number
): RouteLocationNormalized | undefined => {
  return {
    ...to,
    params: {
      ...to.params,
      companyId: companyId.toString(),
    },
  }
}

const useFetchCompanyWithDescendants = () => {
  const queryClient = useQueryClient()
  const { createUrl } = useUrl()

  const queryKey = [Endpoint.Companies]
  const fetchCompanies = () =>
    queryClient.fetchQuery({
      queryKey,
      queryFn: () =>
        universalTypedFetch(
          createUrl(Endpoint.Companies, {
            includeCurrentCompany: true,
            includeDescendantCompanies: true,
          }),
          companySchema.array()
        ),
    })

  const getCompaniesCacheFirst = async () =>
    (queryClient.getQueryState(queryKey)?.isInvalidated
      ? undefined
      : queryClient.getQueryData<Company[]>(queryKey)) ??
    (await fetchCompanies())

  return {
    getCompaniesCacheFirst,
  }
}
